import React from 'react';
import './service.css';

//pages
import Header from '../components/header';
import Footer from '../components/footer';

//images
import OfficeTeam from '../images/officeTeam.png';
import { Link } from 'react-router-dom';

import Bank from '../images/icons/bank.png';
import Manu from '../images/icons/manu.png';
import Health from '../images/icons/health.png';
import Education from '../images/icons/education.png';
import Enter from '../images/icons/enter.png';
import NPT from '../images/icons/npt.png';
import Real from '../images/icons/realestate.png';
import Logistics from '../images/icons/logistics.png';


//icons
import { TfiArrowTopRight } from "react-icons/tfi";





function service() {
  return (
    <div>
    <Header />
    <div className='servicePage'>
    <div>
    <p className='dsLine'>Discover our <span className='ds2'>services.</span></p>
    <p className='dsPara'>The combined expertise of software developers, technical architects, quality engineers, business analysts, and specialists in cloud, AI, ML,
     and security will ensure you build a solution with top quality and functionality.</p>
    <img src={OfficeTeam} className='OfficeTeam'/>
    </div>



    <div className='buttonBoxS'>
    <p className='supercharger'>Supercharge Your <sp className='ds2'>Business</sp></p>
    <Link to='/contact'>
    <button className='startButton'>Get Started</button>
    </Link>
    <div className='Practice'>
    <TfiArrowTopRight className='arrowService' /><p>Strict NDA Policy</p>
    <TfiArrowTopRight className='arrowService' /><p>CCPA, GDPR & DPDPA </p>
    <TfiArrowTopRight className='arrowService' /><p>Global Standards</p>
    
    </div>
    </div>




    <div className='serviceList'>
    <div className='serviceOne'>
    <p className='webMobile'>IOS & Android Mobile Application</p>
    <p className='servicePara'>We specialize in developing captivating, feature-rich mobile applications that drive engagement, enhance user experiences, and propel your business forward. 
    <br/><br/>IOS & Android Native Development
    <br/><br/>Cross-platform Apps
    
    
    </p>
    
    </div>
    <div className='serviceOne'>
    <p className='webMobile'>Web App Development</p>
    <p className='servicePara'>With the right approach to web application development, we can transform your product into a revenue magnet. 

    <br/><br/>Discovery Product-Market fit.
    <br/><br/>Kick-off Development with team
    <br/><br/>Scaling to future development
    </p>
    
    
    </div>
    <div className='serviceOne'>
    <p className='webMobile'>AI Development</p>
    <p className='servicePara'>AI or artificial intelligence, simulates human intelligence in machines, offering numerous benefits for businesses. 
    <br/><br/>
    Task automation<br/><br/>
    Predictive analytics<br/><br/>
    Personalized customer experiences.
    </p>
    </div>
    

    
    </div>
    <div className='serviceList'>
    <div className='serviceOne'>
    <p className='webMobile'>DevOps Services</p>
    <p className='servicePara'>Streamlining the software development process by eliminating time-consuming tasks.
    <br/><br/>
    Migration Service<br/><br/>
    Cloud Management
    
    </p>
    
    
    </div>
    <div className='serviceOne'>
    <p className='webMobile'>Design & Branding</p>
    <p className='servicePara'>We believe a product designer's role extends beyond delivering creative solutions and stunning user interfaces; it's about crafting a product with a high chance of market success.
    <br/><br/>
    Design/UX
    <br/><br/>
    Product redesign
    </p>
    </div>
    <div className='serviceOne'>
    <p className='webMobile'>Startup Partnership</p>
    <p className='servicePara'>Our virtual CTO services provide a fractional or virtual chief technology officer to manage all aspects of your technology platform, allowing you to focus on your business.
    <br/><br/>MVP Design
    <br/><br/>Product Development & Scaling
    <br/><br/>PoC Service
    </p>
    </div>
    

    
    </div>

    <div className='industries'>
    <p className='insTitle'>INDUSTRIES</p>
    <div className='insRow1'>
    <div className='retailBox'>
    <img src={Bank} className='Bank' />
    <p className='titleIns'>BSFI</p>
    
    
    </div>
    <div className='retailBox'>
    <img src={Health} className='Bank' />
    <p className='titleIns'>Health-Care</p>
    
    
    </div>
    <div className='retailBox'>
    <img src={Education} className='Bank' />
    <p className='titleIns'>Education</p>
    
    
    </div>
    <div className='retailBox'>
    <img src={Real} className='Bank' />
    <p className='titleIns'>Real-Estate</p>
    
    
    </div>
    
    
    
    </div>
    <div className='insRow1'>
    <div className='retailBox'>
    <img src={Enter} className='Bank' />
    <p className='titleIns'>Media & Entertainment</p>
    
    
    </div>
    <div className='retailBox'>
    <img src={Manu} className='Bank' />
    <p className='titleIns'>Manufacturing</p>
    
    
    </div>
    <div className='retailBox'>
    <img src={NPT} className='Bank' />
    <p className='titleIns'>Non-Profits</p>
    
    
    </div>
    <div className='retailBox'>
    <img src={Logistics} className='Bank' />
    <p className='titleIns'>Logistics</p>
    
    
    </div>
    
    
    
    </div>
    
    
    
    </div>






    </div>
    
    
    <Footer />
    </div>
  )
}

export default service