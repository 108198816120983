import React from 'react'
import './privacy.css';

//cmp
import Header from '../components/header';
import Footer from '../components/footer';

function privacy() {
  return (
    <div>
    <Header />
    <div className='privacyPage'>
    <div className='privacyTitle'>
    <p className='titleOne'>Programerr Privacy Statement</p>
    <p className='introTitle'>Introduction</p>
    <p className='introPara'>Your privacy is important to us and we believe it is important for you to know what personal data, Programerr “we”, collect from you (and third parties), 
    why we collect it, how we use it and what rights you might be entitled to as a data subject or consumer.<br/><br/><br/>

    Please note: all information in this privacy notice is applicable to you unless otherwise indicated based on your residency status. For the additional terms which may be applicable to you based on your residency status, 
    please refer to your country-specific terms at the end of this notice. In this notice, term “personal data” 
    is used to represent any information relating to an identified or identifiable person; country-specific notices might adopt a different terminology.<br/><br/><br/>
    
    We encourage you to read this notice, together with any additional and more specific information we may provide to you on various occasions when we are collecting or processing personal data on TCS websites, products or applications,
     events and initiatives so that you are aware of how and the purpose for which we are processing your personal data. (Please note: We issue a different privacy notice which applies where there is an employment relationship between Programerr and its employees.)<br/><br/><br/>
    
     We collect personal information from users when they interact with our products, services, and websites. This information may include but is not limited to: name, email address, phone number, address, and payment information. We use this data to provide and improve 
     our products and services, communicate with users, process transactions, and personalize their experience.
    </p>

    <p className='introTitle'>How we will keep your information safe</p>
    <p className='introPara'>We have put in place appropriate technical, organizational and security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to 
    those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. <br/><br/><br/>

    We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.</p>


    <p className='introTitle'>How we will share your personal data</p>
    <p className='introPara'>We may share your data with third parties, including third-party service providers. Please note, Programerr has not in the past, nor does it currently, under any circumstances sell your personal data to any third party. Further,
     Programerr does not share your personal data with third parties for any additional purpose (as outlined below) unless required to fulfill a legal obligation or a legitimate business purpose where permitted by law.</p>
     <p className='miniTitle'>Why might you share my personal data with third parties?</p>

    <p className='introPara'> We may share your personal data with third parties where required by law, where it is necessary for one of the activities mentioned above or where we have another legitimate legal basis in doing so. We require third parties to respect the security of your data and to treat it in 
    accordance with the law. Where required by the law, we will request your consent before transferring data to third parties which are not part of the Programerr.</p>
    <p className='miniTitle'>Which third-party service providers process my personal data?</p>
    <p className='introPara'>”Third parties” includes third-party service providers (including contractors and designated agents) and other entities within our group. The following activities may be carried out by third-party service providers: hosting and other internet services, data storage and analytics, marketing research and campaign management, 
    event organizers and caterers. All our third-party service providers are required to take appropriate security measures to protect your personal data in line with our policies. We do not allow our third-party service providers to use your personal data for their own purposes. We only permit them to process your personal data for specified purposes and in accordance with our instructions and applicable law. We may also need to share your personal data with regulators or to otherwise comply with the law.</p>

    <p className='miniTitle'>When might you share my personal data with other entities in the group?</p>
    <p className='introPara'>We may share your personal data with other entities in the group as part of our regular reporting activities on company performance, in the context of a business reorganization or group restructuring exercise, for system maintenance support and hosting of data and when it is required to do so in order to perform one of the activities listed above (see "How we will use information about you").  Other entities in the group are required to take appropriate security measures to protect your personal data 
    in line with our policies. </p>
    <p className='miniTitle'>How we will keep your information safe</p>
    <p className='introPara'>
    We have put in place appropriate technical, organizational and security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. 
    
    We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.</p>
    <p className='miniTitle'>Our Commitment to Privacy</p>
    <p className='introPara'>We reserve the right to update this privacy notice at any time, and we will provide you with a new privacy notice when we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal data.</p>
    </div>
    
    
    </div>
    <Footer />
    </div>
  )
}

export default privacy