import React from 'react';
import './footer.css';

//images
import LogoWhite from '../images/Instagram post - 20.png';
import { Link } from 'react-router-dom';

//icons
import { FaDribbble } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";

function footer() {
  return (
    <div className='footerPage'>
    <div className='leftBar'>
    <p className='touch'>differentiate</p>
    <p className='growthLine'>from Crowd.</p>
    
    
    </div>
    <div className='companyDetails'>
    <img src={LogoWhite} className='logoW'/>
    <div className='socialMedia'>
    <a href='https://dribbble.com/theprogramerr' target='_blank'><p className='socialName'><FaDribbble /></p></a>
    <a href='https://twitter.com/theprogramerr' target='_blank'><p className='socialName'><FaXTwitter /></p></a>
    <a href='https://www.instagram.com/tech.programerr/' target='_blank'><p className='socialName'><FaInstagram /></p></a>
    <a href='https://www.linkedin.com/company/programerr/mycompany/?viewAsMember=true' target='_blank'><p className='socialName'><SiLinkedin /></p></a>
    <a href='https://www.facebook.com/profile.php?id=100094572542355&notif_id=1715501679119056&notif_t=page_user_activity&ref=notif' target='_blank'><p className='socialName'><FaFacebookF /></p></a>
    </div>
    <div className='address'>
    <a href='https://clutch.co/profile/programerr-software-ai-development-company#highlights' target='_blank'><p className='clutch'>Clutch.co</p></a>
    <p className='addLine'></p>
    
    </div>
    
    </div>
    
    </div>
  )
}

export default footer