import React from 'react'
import Header from '../components/header'

function AppsPage() {
  return (
    <div>
    <Header />
    <div className='appsPage'>
    <h1>Apps Page Bitch</h1>
    
    
    </div>
    
    
    
    </div>
  )
}

export default AppsPage