import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './layout';


//pages
import Home from './pages/home';
import Service from './pages/service';
import Work from './pages/work';
import Contact from './pages/contact';
import Privacy from './pages/privacy';
import About from './pages/about';
import Menu from './pages/menu';
import AppsPage from "./pages/AppsPage";
import ArtPage from './pages/art';


function App() {
  return (
    <Layout>
    <BrowserRouter>
    <Routes>
    <Route exact path="/" element={<Home/>}/>
    <Route path="/service" element={<Service/>}/>
    <Route path="/work" element={<Work/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="/privacy" element={<Privacy/>}/>
    <Route path="/menu" element={<Menu/>}/>
    <Route path='/apps' element={<AppsPage/>}/>
    <Route path='/art' element={<ArtPage/>}/>
    </Routes>
    </BrowserRouter>
    </Layout>
  );
}

export default App;
