import React, {useRef} from 'react';
import { Link } from 'react-router-dom';
import './contact.css';
import emailjs from '@emailjs/browser';

//cmp
import Header from '../components/header';
import Footer from '../components/footer';



function Contact() {

  
    const form =useRef()

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_7r7kzwj', 'template_4z1j885', form.current, {
          publicKey: 'I8tNiHbKDNjcPl1Xq',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        ); e.target.reset()
    };


  return (
    <div>
    <Header />

    <div className='contactPage'>
    
    <div className='companyBox'>
    <p className='companyName'>Programerr <span className='dot1'>.</span><br/><span className='dsc'>Digital Service Company</span></p>
    <p className='companyPara'>At our core, we understand the fast-paced nature of the business world, where timely decisions and agile responses are critical. That's why we have honed our expertise to offer streamlined solutions that empower businesses to adapt, thrive, and stay ahead of the curve.

    </p>
    <div className='directCall'>
    <p className='contactHead'>Contact Programerr</p>
    <p className='contactLineTwo'>We appreciate your interest. Please select from the options below.</p>
    </div>
    <div className='details'>
    <p className='detailsOne'>General Inquiries</p>
    <div  className="infoDetails">
    <p className='mobileNumber'>+91 9445360482<br/>(WhatsApp)</p>
    <p className='emailContact'>business@programerr.com</p>
    </div>
    
    
    </div>
    </div>




    <div className='contactBox'>
    <p className='contactTitle'>Get in touch!</p>

    <form ref={form}  onSubmit={sendEmail}>
    <div className='nameInput'>
    <input className='firstName' placeholder='First Name' name='user_Name' required autoComplete='off'/>
    <input className='firstName' placeholder='Last Name' name='user_Name' required autoComplete='off'/>
    </div>
    <div className='secondInput'>
    <input className='emailInput' placeholder='Email Address' name='email' required autoComplete='off' />
    <input className='emailInput' placeholder='Organization' required autoComplete='off'/>
    <textarea name='message' cols='30' rows='10' type='text' required placeholder='ask your need?'></textarea>
    <div className='consentBox'>
    <input type='checkbox' required autoComplete='off'/>
    <p className='consent'>I Consent to processing of my personal data entered above for the purpose of recording the feedback*</p>
    </div>
    </div>
    <p className='further'>Further details on how your personal data will be processed and how your consent can be managed, refer to the</p>
    <ul>
    <Link to='/privacy'><p className='privacy'>Programerr's Privacy Statement</p></Link>
    </ul>
    <div className='sendBox'>
    <p className='need'>*Mandatory fields</p>
    <button className='sendButton'>Send</button>
    </div>
    </form>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Contact