import React from 'react';
import'./menu.css';
import { Link } from 'react-router-dom';

import Home from '../images/home.png';

function menu() {
  return (
    <div className='menuPage'>
    
    <ul className='menuList'>
    <Link to='/service'><p className='menuNames'>Service</p></Link>
    <Link to='/work'><p className='menuNames'>Work</p></Link>
    <Link to='/about'><p className='menuNames'>About</p></Link>
    <Link to='/contact'><p className='menuNames'>Contact</p></Link>
    </ul>
    <div className='homeBox'>
    <ul>
    <Link to='/'><img src={Home} className='homeButton' /></Link>
    </ul>
    </div>
    </div>
  )
}

export default menu