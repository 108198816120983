import React from 'react';
import './work.css';
//cmp
import Header from '../components/header'
import Footer from '../components/footer';

//images
import Fox from '../images/fox.jpg';
import Insurance from '../images/insuranceDelta.jpg';
import Violence from '../images/violence.jpg';
import Gym from '../images/gym.jpg';
import { Link } from 'react-router-dom';
import Premium from '../images/premium.png';
import Inter from '../images/integrity.png'


function work() {
  return (
    <div>
    <Header />
    <div className='workPage'>
    <div className='workOne'>
    <p className='workLine1'>Following a Product redesign, our clients typically undergo significant changes, experiencing:</p>
    <div className='workList'>
    <div className='workListOne'>
    <p className='workNumber'>84%+</p>
    <p className='workNumber2'>
    monthly visit</p>
    
    </div>
    <div className='workListOne'>
    <p className='workNumber'>92%+</p>
    <p className='workNumber2'>
    increased conversion</p>
    
    </div>
    <div className='workListOne'>
    <p className='workNumber'>91%+</p>
    <p className='workNumber2'>
    Leads Generated.</p>
    
    </div>
    
    
    </div>

    <div className='conceptRow1'>
    <div className='firstC'>
    <img src={Fox} className='fox'/>
    <p className='lineDraw'>_________________________</p>
    <p className='domain'>Logistics</p>
    </div>
    <div className='firstC'>
    <img src={Insurance} className='insurance'/>
    <p className='lineDraw'>_________________________</p>
    <p className='domain'>Insurance</p>
    </div>
    
    
    </div>
    <div className='conceptRow1'>
    <div className='firstC'>
    <img src={Violence} className='fox'/>
    <p className='lineDraw'>_________________________</p>
    <p className='domain'>Fashion & Apparel</p>
    </div>
    <div className='firstC'>
    <img src={Gym} className='fox'/>
    <p className='lineDraw'>_________________________</p>
    <p className='domain'>Gym & Fitness</p>
    </div>
    
    
    </div>

    </div>
    <div className='importantLine'>
    <p className='focusLine'>Our focus on what’s important for you and<br/> your new digital experience.</p>
    <ul>
    <Link to='/contact'>
    <button className='callButton22'>Let's Talk</button>
    </Link>
    </ul>
    </div>
    <div className='statement'>
    <p className='strong'>We strongly uphold</p>
    <div className='statementMini'>

    <div className='Standard'>

    <div className='stdClass'>
    <p className='stdTitle'>Standard</p>
    <img src={Premium} className='star1' />
    </div>
    <p className='lineDraw'>_______________________________________________</p>
    <p className='stdLine'>We Maintaining high quality and standards involves to best practices, following protocols, 
    continuously improving processes, and for customer satisfaction. It reflects the commitment and 
    professionalism within individual's work.</p>
    
    
    </div>
    <div className='Standard'>

    <div className='stdClass2'>
    <p className='stdTitle'>Transparency</p>
    <img src={Inter} className='star1' />
    </div>
    <p className='lineDraw'>_______________________________________________</p>
    <p className='stdLine2'>We work with clear communication, honesty, and accessibility of information, allowing stakeholders 
    to understand the reasons behind actions, the status of tasks, and the overall direction of the work being done.</p>
    
    
    </div>
    
    
    </div>
    <div>
    <p></p>
    </div>
    
    </div>
    </div>
    
    <Footer />
    </div>
  )
}

export default work