import React from 'react';
import Header from '../components/header';
import './art.css';

import artPic from '../images/artImg2.jpg';

function art() {
  return (
    <div>
    <Header />
    <div className='artPage'>
    <div>
    <img src={artPic} className='artPic' />
    
    </div>
    
    </div>
    
    </div>
  )
}

export default art