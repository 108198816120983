import React from 'react';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import  './home.css';


//comp
import Header from '../components/header';
import Footer from '../components/footer';



//images
import Mobile from '../images/mobile.png';
import StartRocket from '../images/rocket11.png';
import rocket22 from '../images/rocket22.png';
import rocket33 from '../images/rocket33.png';
import rocket44 from '../images/rocket44.png';
import GenZ from '../images/genZ.jpg';
import Working from '../images/working.jpg';
import Working2 from '../images/working2.jpg';
import Working3 from '../images/working3.png';
import Working4 from '../images/working4.png';
//icons
import { IoArrowForwardSharp } from "react-icons/io5";
import { IoMdArrowForward } from "react-icons/io";


import Hotjar from '@hotjar/browser';

const siteId = 4986499;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);



function Home() {
  return (
    <div>
    <Header/>
    <div className='homePage'>
    <Helmet>
    <title>Digital Service Company</title>

    <meta name="Web Design" content="web design for conversion" />
    <meta name="Web Development" content="web development for conversion" />
    <meta name="ecommerce" content="web design for ecommerce" />
    <meta name="application" content="web design for application" />
    <meta name="seo ranking" content="web design & seo ranking" />
    <meta name="salesforce" content="salesforce for your business" />
    <meta name="software" content="software development company" />
    </Helmet>
    <div className='firstPage'>
    <div className='leftSide1'>
    <p className='stayAhead'>Get your "MVP" <br/><sp className='stay2'>below </sp>90 days <br/>for Startups.</p>
  
    <p className='line2'>With 5+ years experiencing and studying the market atmosphere, people's buying patterns, and how product market-fit, <br/><sp className='targetSpan'>we could target precise.</sp></p>
    <div className='buttons1'>
    <Link to='/contact'>
    <button className='call1'>Schedule Call <IoArrowForwardSharp className='arrow2' /></button>
    </Link>
    <Link to='/work'><p className='vs1'>View Insights</p></Link>
    
    </div>
    </div>
    <div className='rightBox1'>
    <img src={Mobile} className='mobilePic' />
    </div>
    </div>
    <div className='secondUpper'>
    <p className='serviceTitle1'>Everything we do, <span className='growSpan'>what help you to grow?</span>
    </p>
    <div className='secondPage'>
  
    <div className='serviceBox11'>
    <p className='appDev1'>Application & Software<br/><span className='spanLine2'>Development</span></p>
    <div className='serviceNames'>
    <p>Web Application</p>
    <p>Mobile Apps, (IOS & Android)</p>
    <p>Salesforce Integration</p>
    <p>DevOps Service</p>
    
    </div>
    <div className='buttonDiv1'>
    <img className='startRocket' src={StartRocket} />
    </div>
    </div>
    <div className='serviceBox11'>
    <p className='appDev1'>Artificial Intelligence<br/><span className='spanLine2'>Development</span></p>
    <div className='serviceNames'>
    <p>Generative AI</p>
    <p>Smart AI Assistants and Chatbot</p>
    <p>AI Product Development</p>
    <p>AI-as-a-Service (AIaaS)</p>
    <p>AI predictive modeling</p>
    
    </div>
    
    <div className='buttonDiv2'>
    <img src={rocket22} className='rocket22' />
    </div>
    </div>
    <div className='serviceBox11'>
    <p className='appDev12'>Design &<br/>Branding</p>
    <div className='serviceNames'>
    <p>Web design/UX</p>
    <p>Product design</p>
    <p>Pitch deck design</p>
    <p>Graphic design</p>
    
    </div>
    
    <div className='buttonDiv3'>
    <img src={rocket33} className='rocket33' />
    </div>
    </div>
    <div className='serviceBox11'>
    <p className='appDev1'>Startup-<br/>Partnership</p>
    <div className='serviceNames'>
    <p>CTO-as Service</p>
    <p>PoC Service</p>
    <p>MVP Design</p>
    <p>Product Scaling</p>
    
    </div>
    <div className='buttonDiv4'>
    <img src={rocket44} className='rocket44' />
    </div>
    </div>
    
    
    </div>
    <div>
    <p className='whatGet'>What you will <span className='getWhat'>get?</span></p>
    <div className='secondRowBox'>
    <div className='iconClass'>
  
    <p className='qualityLine'>We focus on the fastest route to market – respecting your time and pushing your development to success.</p>
    </div>
    <div  className='iconClass'>
  
    <p className='qualityLine'>The goal is to get you the best team of engineers in the shortest time and start delivering real business value.</p>
    </div>
    <div  className='iconClass'>
    <p className='qualityLine'>Great relationships with our focus to be a reliable and long-term partner</p>
    </div>
    
    
    
    </div>
    </div>
    
    </div>

    <div className='thirdPage'>
    <div className='thirdLower'>
    <p className='thirdTitle'>the <sp className='getWhat'>momentum</sp> process</p>
    </div>
    <div className='thirdRowOne'>
    <div className='discover'>
    <p className='numbers'>01</p>
    <p className='discovery'>Discovery</p>
    <p className='disLine2'>Gathering and analyzing the technical and financial feasibility, identifying potential risks and mitigation strategies, and developing a high-level project plan and timeline.</p>
    </div>
    <div className='discover'>
    <p className='numbers'>02</p>
    <p className='discovery'>Staffing</p>
    <p className='disLine2'>Next, proper onboarding and training will provide to align everyone with the project goals & Clear communication channels and a collaborative environment are crucial for effective teamwork.</p>
    </div>
    <div className='discover'>
    <p className='numbers'>03</p>
    <p className='discovery'>Kick-off</p>
    <p className='disLine2'>Your remote team will be ready to onboard & set a clear direction, and ensure a common understanding of the project’s approach and objectives.</p>
    </div>
    <div className='discover'>
    <p className='numbers'>04</p>
    <p className='discovery'>Scaling</p>
    <p className='disLine2'>includes assessing the technical and financial feasibility, identifying potential risks and mitigation strategies, and developing a high-level project plan and timeline.</p>
    </div>
    
    </div>
    
    
    
    </div>
    <div className='fourthLine'>
    <p className='market'>Market Insights</p>
    
    <div className='firstRow4'>
    <div className='lowInvest'>
    <p className='vcPe'>VC and PE firms Investments</p>
    <p className='genAI'>Does Startup's with AI attract Investment or downgrade?</p>
    <a href='https://medium.com/@programerrcompany/does-startups-with-ai-attract-investment-or-downgrade-bf03c95f7979' target='_blank' className='readNow'>Read Now</a>
    </div>
    <div>
    <img src={GenZ} className='genZ' />
    </div>
    
    
    </div>
    <div className='firstRow5'>
    <p className='TA1'>Trending Articles</p>
    <div className='workingRow'>
    <div>
    <img src={Working} className='working'  />
    <p>VC firms</p>
    <p className='workingTitle'>In which AI Product attracts more investment?</p>
    <a href='https://medium.com/@programerrcompany/in-which-ai-product-attracts-more-investment-c6a4997fffc2' target='_blank' className='readNow2'>Learn more</a>
    </div>
    <div>
    <div>
    <img src={Working2} className='working'/>
    <p>Future computing</p>
    <p className='workingTitle'>Future of metaverse, how it would be in 2030?</p>
    <a href='https://medium.com/@programerrcompany/future-of-metaverse-how-it-would-be-in-2030-4bfc2dfae1a2' target='_blank' className='readNow2'>Learn more</a>
    </div>
    </div>
    <div>
    <div>
    <img src={Working3} className='working'  />
    <p>Work places</p>
    <p className='workingTitle'>It is really hard to work with Gen Z?</p>
    <a href='https://medium.com/@programerrcompany/it-is-really-hard-to-work-with-gen-z-2e2891728773' target='_blank' className='readNow2'>Learn more</a>
    </div>
    </div>
    <div>
    <div>
    <img src={Working4} className='working'  />
    <p>Investment</p>
    <p className='workingTitle'>Product redesign to boost investments, how it works?</p>
    <a href='https://medium.com/@programerrcompany/product-redesign-to-boost-investments-how-it-works-70a6d315012d' target='_blank' className='readNow2'>Learn more</a>
    </div>
    </div>
    </div>
    
    </div>
    
    </div>




    
    </div>
    
    <Footer/>
    </div>
    
  )
}

export default Home