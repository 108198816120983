import React from 'react';
import './about.css';

//cmp
import Header from '../components/header'
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import { ImLinkedin } from "react-icons/im";

function about() {
  return (
    <div>
    <Header />
    <div className='aboutPage'>
    <div className='pageFront'>
    <p className='front11'>We came to break,<br/>
    being Normal.</p>
    <p className='front22'>With team of individual ownership, <br/>
    we breaking stereotypes. </p>
    </div>
    <div className='frontBox11'>
    <div className='aboutBox11'>
    <p className='oneTitle'>About Us.</p>
    <p className='aboutLine'>______________________________________</p>
    <p className='aboutPara11'> from 2023, we solving your business problems, especially 
    when it comes to getting more people to buy your stuff. We here to help you turn more visitors into paying customers. </p>
    </div>
    <div className='aboutBox22'>
    <p className='oneTitle'>Mission to</p>
    <p className='aboutLine'>______________________________________</p>
    <p className='aboutPara11'>We here to turn every possible actions into revenue. So that, we don't 
    build just look good, but also turn visitors into customers.</p>
    </div>
    
    
    </div>
    <div className='frontBox22'>
    <div className='aboutBox11'>
    <p className='oneTitle'>founder’s<br/>
    pledge.</p>
    <a href='https://www.linkedin.com/in/vignesh-kothandaraman-80254824a/' target='_blank'>
    <ImLinkedin className='LinkedIn' />
    </a>
    
    
  
    <p className='aboutLine2'>______________________________________</p>
    <p className='aboutPara11'>We pledge, not just a promise, but a commitment to drive meaningful <br/>change 
    with legacy of business, <br/>integrity, and social responsibility.</p>
    </div>
    <div className='aboutBox22'>
    <p className='oneTitle'>Team of warriors</p>
    <p className='aboutLine'>______________________________________</p>
    <p className='aboutPara11'>We believe power of human connection with creating a safe and non-judgmental
     space where individuals can freely express their concerns and receive the support they need.</p>
    </div>
    
    
    </div>
    
    
    </div>
    
    
    
    <Footer />
    </div>
  )
}

export default about