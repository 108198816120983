// Layout.js

import React from 'react';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <title>Programerr</title>
        <meta name="Web Design" content="web design for conversion" />
        <meta name="Web Development" content="web development for conversion" />
        <meta name="ecommerce" content="web design for ecommerce" />
        <meta name="application" content="web design for application" />
        <meta name="seo ranking" content="web design & seo ranking" />
        <meta name="salesforce" content="salesforce for your business" />
        <meta name="software" content="software development company" />


        {/* Add common meta tags for all pages */}
      </Helmet>
      {children}
    </div>
  );
};

export default Layout;
