import React from 'react';
import Lottie from 'lottie-react';



//images
import Logo from '../images/programerr logo.png';
import menu1 from '../images/menu (1).png';

//css
import './header.css';
import { Link } from 'react-router-dom';
import { IoArrowForwardSharp } from "react-icons/io5";
import { TbMenuDeep } from "react-icons/tb";




function Header() {
  return (
    <div className='headerPage'>
    
    <div className='brandLogo'>
    <Link to='/'>
    <img src={Logo} className='brandName' />
    </Link>
    
    </div>
    <div className='navList'>

   
    <Link to='/service'>
    <p className='listNav'>Service</p>
    </Link>
    
    <Link to='/about'>
    <p className='listNav'>About</p>
    </Link>
    
    <Link to='/work'>
    <p className='listNav'>Insights</p>
    </Link>
    

    <Link to='/contact'>
    <button className='talk'>
    Reach Out <IoArrowForwardSharp className='arrow' />
    </button>
    </Link>
    
    </div>
    <div className='menu'>
    <Link to='/menu'>
    <TbMenuDeep className='menuColor' />
    </Link>
    
    </div>
    </div>
  )
}

export default Header